<template>
  <div class="background-validate content-page">
    <div>
      <InputOTP 
        @submitValidateOTP="submitValidateOTP" 
        :type="1"
        @clickChangePhone="clickChangePhone"
      />
    </div>
    <ModalError ref="modalError" :text="successMessage" />
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import ModalError from "@/components/alert-modal/ModalError";
import InputOTP from "@/components/input/InputOTP";
import { required, minLength, integer } from "vuelidate/lib/validators";
export default {
  name:"confirmotp",
  components: {
    OtherLoading,
    ModalError,
    InputOTP
  },
  data() {
    return {
      successMessage: ""
    };
  },
  methods: {
    submitValidateOTP: async function (val) {
      await this.$axios
      .post(`${process.env.VUE_APP_API}/api/v1/user/VerifyOTP`, val)
      .then(async data => {
        this.isLoading = false;
        if (data.result == 1) {
          if(data.detail.is_new_user == 1){
            //new User
            this.$router.push({ path: 'register', query: { tel: val.Telephone } })
            // this.$router.push("/register");
          }else{
            // Old User
            this.$cookies.set("hourglass_token", data.detail.token);
            this.$router.push("/review");
          }
        } else {
          this.successMessage = data.message;
          this.$refs.modalError.show();
        }
      });
    },
    clickChangePhone(val){
      this.$router.push('/validatetelephone');
    }
  },
  validations() {
    return {
      form: {
        OTP: {
          minLength: minLength(6),
          integer,
          required
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.input-number {
  align-items: center;
  justify-content: center;
}
.bg-trans {
  background-color: transparent;
}
.background-validate{
  background-color: #fff;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
}
.content-page{
  margin-top: 80px;
}
</style>
